import './configurable-variant-component.css'

import { twMerge } from 'tailwind-merge'

import { Select } from '../select'
import { Field } from '../field'
import { ConfigurableProductOptionFragment } from '@/api'
import { ConfigurableVariantAttribute } from './utils/get-configurable-variant-attributes'

type Props = {
  disabled: boolean
  label: string
  className?: string
  onChange: (value: ConfigurableVariantAttribute) => void
  availableAttributeCombinations?: ConfigurableVariantAttribute[]
  configurableOptions: (ConfigurableProductOptionFragment | null | undefined)[]
  selectedAttributes?: ConfigurableVariantAttribute
  option?: ConfigurableProductOptionFragment
}

export function ConfigurableVariant({
  disabled,
  className,
  onChange,
  label,
  availableAttributeCombinations,
  configurableOptions,
  selectedAttributes,
  option,
}: Props): JSX.Element {
  const handleOnSelectChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
  ) => {
    const value = parseInt(event.target.value, 10)

    if (option?.attribute_code) {
      onChange({
        ...selectedAttributes,
        [option.attribute_code]: {
          label: combined.find((c) => c.value === value)?.label ?? '',
          valueIndex: value,
        },
      })
    }
  }

  const filteredAttrCombinations = availableAttributeCombinations?.filter(
    (combination) => {
      if (option?.position === 0) {
        return true
      }

      const attrIdx =
        combination[configurableOptions?.[0]?.attribute_code ?? '']?.valueIndex

      const selectedAttrIdx =
        selectedAttributes?.[configurableOptions?.[0]?.attribute_code ?? '']
          ?.valueIndex

      return attrIdx === selectedAttrIdx
    },
  )

  const labels = [
    ...new Set(
      filteredAttrCombinations?.map(
        (attr) => attr[option?.attribute_code ?? '']?.label,
      ),
    ),
  ]

  const values = [
    ...new Set(
      filteredAttrCombinations?.map(
        (attr) => attr[option?.attribute_code ?? '']?.valueIndex,
      ),
    ),
  ]

  const combined = labels.map((label, index) => ({
    label,
    value: values[index],
  }))

  const isSelectedInFilteredCombinations = combined
    .map(({ value }) => value)
    .includes(
      selectedAttributes?.[option?.attribute_code ?? '']?.valueIndex ?? 0,
    )

  // If selected attribute is not in the filtered combinations
  // then select the first option and let know in the context for need of update
  //
  // This is used here because when combination is not available, context is not aware of this change
  // and it will not update the selected variant and we will not be able to add product to cart
  if (!isSelectedInFilteredCombinations && option?.attribute_code) {
    onChange({
      ...selectedAttributes,
      [option.attribute_code]: {
        label: combined?.[0]?.label ?? '',
        valueIndex: combined?.[0]?.value,
      },
    })
  }

  const selectedValue = isSelectedInFilteredCombinations
    ? selectedAttributes?.[option?.attribute_code ?? '']?.valueIndex ?? 0
    : combined?.[0]?.value

  return (
    <div className={twMerge('text-left', className)}>
      <Field required label={label}>
        <Select
          onChange={handleOnSelectChange}
          value={selectedValue}
          className="w-full text-sm min-h-[28px] mt-1 appearance-none bg-white bg-no-repeat pr-[2em] variants-dropdown"
          aria-label={label ?? ''}
          disabled={disabled}
        >
          {combined.map((variant) => (
            <option key={variant.value} value={variant.value ?? undefined}>
              {variant.label}
            </option>
          ))}
        </Select>
      </Field>
    </div>
  )
}

'use client'

import { ChangeEvent, KeyboardEvent, useCallback, useState } from 'react'
import { useTranslations } from 'next-intl'
import { twJoin } from 'tailwind-merge'

import { selectors } from '@/common/constants/selectors-constants'
import { IconButton } from '../icon-button'
import { ActionMinusIcon, ActionPlusIcon } from '../icons/svg'
import { Input } from '../input'

type Props = {
  count: number
  onCountChange: (count: number) => void
  onAddToCart?: () => void
  isDisabled?: boolean
}

export function VariantsCounter({
  count,
  onCountChange,
  onAddToCart,
  isDisabled,
}: Props): JSX.Element {
  const [localCount, setLocalCount] = useState<number | ''>(count)
  const t = useTranslations('Product')

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const targetValue = e.target.value

      // special case of empty field
      if (targetValue === '') {
        setLocalCount('')
        onCountChange(1)
        return
      }

      const numberValue = Number(targetValue)

      if (numberValue >= 1 && numberValue <= 99999) {
        onCountChange(numberValue)
        setLocalCount(numberValue)
      } else {
        onCountChange(count)
        setLocalCount(count)
      }
    },
    [count, onCountChange],
  )

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onAddToCart?.()
        setLocalCount(count)
      }
      if (ALLOWED_KEYS.every((allowedKey) => allowedKey !== event.key)) {
        event.preventDefault()
      }
    },
    [onAddToCart, count],
  )

  const decreaseCount = useCallback(() => {
    onCountChange(count - 1)
    setLocalCount(count - 1)
  }, [count, onCountChange])

  const increaseCount = useCallback(() => {
    onCountChange(count + 1)
    setLocalCount(count + 1)
  }, [count, onCountChange])

  return (
    <div className="min-w-fit flex flex-col items-start gap-1 justify-between">
      <div className="md:invisible">
        <label className="whitespace-nowrap text-sm mb-1">
          {t('detail.variants.count')}
        </label>
      </div>

      <div className="flex items-center gap-2">
        <IconButton
          variant="transparent"
          onClick={decreaseCount}
          disabled={isDisabled || count === 1}
          size="sm"
          className="h-7"
          aria-label={`${t('detail.variants.count')}} ${t(
            'common.button.minus',
          )}`}
        >
          <ActionMinusIcon width="20px" height="20px" />
        </IconButton>
        <Input
          type="number"
          value={localCount}
          onChange={handleOnChange}
          onKeyDown={handleKeyPress}
          disabled={isDisabled}
          step={1}
          min={1}
          max={20000}
          aria-label={t('detail.variants.count')}
          className={twJoin(
            'text-center',
            'p-0',
            '[-moz-appearance:_textfield] [&::-webkit-outer-spin-button]:m-0 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none',
          )}
          inputMode="decimal"
          pattern="[0-9]*"
          data-test={selectors.PDP.quantityInput}
        />

        <IconButton
          variant="transparent"
          onClick={increaseCount}
          disabled={isDisabled}
          size="sm"
          className="h-7"
          aria-label={`${t('detail.variants.count')}} ${t(
            'common.button.plus',
          )}`}
        >
          <ActionPlusIcon width="20px" height="20px" />
        </IconButton>
      </div>
    </div>
  )
}

const ALLOWED_KEYS = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'Enter',
  'Backspace',
  'Delete',
  'ArrowUp',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'Escape',
]

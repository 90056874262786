export enum GtmTrackerState {
  Started = 1,
  PageView = 2,
  Remarketing = 3,
  User = 4,
  VirtualPageview = 5,
  ProductData = 6,
}

/**
 * Scheduler for Google Tag Manager events
 * ensures that events are processed in the correct order and that no events are lost
 * if they are scheduled before the required state is reached
 */
export class GtmScheduler {
  private currentState: GtmTrackerState | undefined = undefined

  private initialEvents: { [key: number]: (() => void) | undefined } = {}
  private events: (() => void)[] = []
  private lock: { [key: number]: boolean } = {}
  private requiredStates: GtmTrackerState[] = []

  /**
   * @param requiredStates - required initial states in the order they should be processed
   */
  constructor(requiredStates: GtmTrackerState[]) {
    this.requiredStates = requiredStates
  }

  /**
   * Schedules an event to be processed when the required state is reached
   * @param state - required state for the event to be processed
   * @param gtmEvent - event to be processed
   */
  scheduleInitialEvent = (state: GtmTrackerState, gtmEvent: () => void) => {
    if (this.isInitialized()) {
      this.events.forEach((event) => event())

      if (!this.isEventLocked(state)) {
        gtmEvent()
        this.lockEvent(state)
      }
    } else {
      const eventEnabled = this.currentState
        ? state <= this.currentState
        : state === this.requiredStates[0]

      if (eventEnabled) {
        if (!this.isEventLocked(state)) {
          gtmEvent()
          this.lockEvent(state)
          this.currentState = this.findNextState(state)
        }
      } else {
        this.initialEvents[state] = gtmEvent
      }

      this.processPreviousEvents()
    }
  }

  scheduleEvent = (gtmEvent: () => void) => {
    if (this.isInitialized()) {
      gtmEvent()
    } else {
      this.events.push(gtmEvent)
    }
  }

  isInitialized = (): boolean =>
    this.requiredStates.length > 0
      ? this.currentState ===
        this.requiredStates[this.requiredStates.length - 1]
      : true

  /*
   * Processes all events that were scheduled before the required state was reached
   */
  private processPreviousEvents = () => {
    for (let state in this.initialEvents) {
      const stateId = parseInt(state, 10)
      const stateEnabled = this.currentState
        ? stateId <= this.currentState
        : stateId === this.requiredStates[0]

      if (this.initialEvents[stateId] && stateEnabled) {
        this.processPreviousEvent(stateId)
      }
    }
  }

  private processPreviousEvent = (stateId: GtmTrackerState) => {
    const event = this.initialEvents[stateId]
    const updateRequired = this.currentState && stateId >= this.currentState

    if (event) {
      event()
      this.clearBufferedEvent(stateId)
    }

    if (updateRequired) {
      this.currentState = this.findNextState(stateId)
      this.processPreviousEvents()
    }
  }

  private clearBufferedEvent = (state: GtmTrackerState) => {
    this.initialEvents[state] = undefined
  }

  private findNextState = (state: GtmTrackerState): GtmTrackerState => {
    const index = this.requiredStates.findIndex(
      (requiredState) => requiredState === state,
    )

    return index < this.requiredStates.length - 1
      ? this.requiredStates[index + 1]
      : state
  }

  private isEventLocked = (state: GtmTrackerState): boolean =>
    !!this.lock[state]

  private lockEvent = (state: GtmTrackerState) => {
    this.lock[state] = true
  }
}

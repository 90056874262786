'use client'

export const SKU_RECENTLY_VIEWED_PRODUCT = 'sku_recently_viewed_product'

interface ProcessRecentlyViewedProductsProps {
  productSku: string | null | undefined
  outOfStock?: boolean
}

export const processRecentlyViewedProducts = ({
  productSku,
  outOfStock = true,
}: ProcessRecentlyViewedProductsProps) => {
  if (outOfStock) {
    return
  }

  if (productSku) {
    const productSkus = JSON.parse(
      localStorage.getItem(SKU_RECENTLY_VIEWED_PRODUCT) ?? '[]',
    ) as string[]
    if (productSku) {
      const filteredSkus = productSkus.filter((sku) => sku !== productSku)
      const updatedSkus = [productSku, ...filteredSkus].slice(0, 6)

      localStorage.setItem(
        SKU_RECENTLY_VIEWED_PRODUCT,
        JSON.stringify(updatedSkus),
      )
    }
  }
}

import React, { PropsWithChildren } from 'react'

import { cn } from '@/common/utils/lib'
import { WithClassName } from '@/common/types/utility'
import { Flex } from '../flex'

interface FieldProps {
  error?: string
  label?: string | null
  required?: boolean
}

export const Field = ({
  label,
  error,
  required,
  children,
  className,
}: PropsWithChildren<WithClassName<FieldProps>>) => (
  <Flex className={cn('flex-col', className)}>
    {label && (
      <label className="text-sm text-grey-700">
        {label}

        {required && <span className="text-red-500 ml-1">*</span>}
      </label>
    )}

    <div className="relative mt-1">{children}</div>

    {error && <div className="text-red-500">{error}</div>}
  </Flex>
)

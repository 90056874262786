import { ConfigurableProductVariantFragment, ProductStockStatus } from '@/api'

export const getConfigurableProductVariantByHash = (
  configurableVariants:
    | (ConfigurableProductVariantFragment | null | undefined)[]
    | null
    | undefined,
  hash: string,
) => {
  const numberHash = +hash

  if (Number.isNaN(numberHash)) {
    return undefined
  }

  return configurableVariants?.find(
    (variant) =>
      variant?.product?.id === numberHash &&
      variant?.product?.enabled &&
      variant?.product?.stock_status === ProductStockStatus.InStock,
  )
}

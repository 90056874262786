import { ConfigurableProductVariantFragment } from '@/api'

export type ConfigurableVariantAttribute = Record<
  string,
  { label: string; valueIndex: number }
>

type ConfigurableVariantAttributeFragment = {
  code?: string | null
  label?: string | null
  value_index?: number | null
}

function createVariantAttribute(
  variantAttributes?: (ConfigurableVariantAttributeFragment | null)[],
) {
  return variantAttributes?.reduce((acc, curr) => {
    if (!curr) {
      return acc
    }

    const { code, label, value_index: valueIndex } = curr

    // Attribute code is unique per each variant
    // e.g. variant cannot have multiple flavors at the same time
    if (code && !acc[code]) {
      return {
        ...acc,
        [code]: { label: label ?? '', valueIndex: valueIndex ?? 0 },
      }
    }

    return acc
  }, {} as Record<string, { label: string; valueIndex: number }>)
}

export function getAvailableAttributeCombinations(
  variants?: (ConfigurableProductVariantFragment | undefined | null)[],
): Record<string, { label: string; valueIndex: number }>[] | undefined {
  return variants
    ?.sort((a) => (a?.is_bestseller ? -1 : 1))
    .reduce((acc, variant) => {
      const variantAttributes = variant?.attributes

      const mappedAttrs = createVariantAttribute(variantAttributes ?? undefined)

      if (!mappedAttrs) {
        return acc
      }

      return [...acc, mappedAttrs]
    }, [] as Record<string, { label: string; valueIndex: number }>[])
}

export function getPreselectedVariantAttributes(
  selectedVariantAttributes?: (ConfigurableVariantAttributeFragment | null)[],
) {
  return createVariantAttribute(selectedVariantAttributes ?? undefined)
}

'use client'

import { useTranslations } from 'next-intl'
import { useMemo } from 'react'

export const useAddToCartBtnText = ({
  changeText,
  isAddingToCart,
  isCartRefetching,
  isCartFetchError,
}): string => {
  const t = useTranslations('Product')

  const buttonText = useMemo(() => {
    if (changeText && isAddingToCart) {
      return t('common.button.addingToCart')
    }

    if (changeText && isCartRefetching && !isCartFetchError) {
      return t('common.button.addedToCart')
    }

    return t('common.button.addToCart')
  }, [t, changeText, isAddingToCart, isCartFetchError, isCartRefetching])

  return buttonText
}
